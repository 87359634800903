import { computed, Injectable, signal } from '@angular/core';
import { VerificationTypeEnum } from 'src/app/shared/form-inputs/enums/verification-type.enum';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly _account = signal<any>(undefined);
  private readonly _pharmacy = signal<any>(undefined);
  private readonly _address = signal<any>(undefined);
  private readonly _avatars = signal<Array<{ userId: string; avatar: string | undefined }>>([]);
  account = computed(this._account);
  pharmacy = computed(this._pharmacy);
  address = computed(this._address);
  avatars = computed(this._avatars);
  private readonly _reviewData = signal<any>(undefined);

  updateAccount(newData: any): void {
    this._account.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  updatePharmacy(newData: any): void {
    this._pharmacy.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  updateAddress(newData: any): void {
    this._address.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  updateAvatars(userId: string, avatarUrl: string | undefined): void {
    this._avatars.update((oldAvatars) => {
      const avatarIndex = oldAvatars.findIndex((avatar: any) => avatar.userId === userId);

      if (avatarIndex > -1) {
        const updatedAvatars = [...oldAvatars];
        updatedAvatars[avatarIndex] = { userId, avatar: avatarUrl };
        return updatedAvatars;
      } else {
        return [...oldAvatars, { userId, avatar: avatarUrl || '' }];
      }
    });
  }

  setReviewData(reviewType: VerificationTypeEnum, data: string): any {
    this._reviewData.set({ reviewType, dataInfo: data });
  }

  get reviewData(): any {
    return this._reviewData();
  }

  clear(): void {
    this._account.set(undefined);
    this._pharmacy.set(undefined);
    this._address.set(undefined);
    this._reviewData.set(undefined);
    this._avatars.set([]);
  }
}

import { Injectable, Injector, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { CapacitorHttp } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { LabelValuePair } from '../shared/model/label-value-pair.interface';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly _languages = signal<any>(undefined);

  constructor(private readonly injector: Injector) {}

  async getLanguages(): Promise<any> {
    if (this.languages()) return this.languages();
    const authService = this.injector.get(AuthService);
    const accessToken = await authService.getToken();
    const res = await CapacitorHttp.get({
      url: `${environment.ollaApiUrl}/api/language/language`,
      headers: { 'X-TENANT-ID': environment.orgSlug, Authorization: `Bearer ${accessToken}` },
      responseType: 'json',
    });

    if (res.status < 200 || res.status >= 300) throw new Error();
    const languages = res.data
      .filter((l: any) => ['en-US', 'es-ES'].includes(l.code))
      .map((l: any) => ({ label: l.language, value: l.code }));
    this.setLanguages(languages);
    return languages;
  }

  setLanguages(languages: LabelValuePair[]): any {
    this._languages.set(languages);
  }

  get languages(): any {
    return this._languages;
  }

  clear(): void {
    this._languages.set(undefined);
  }
}

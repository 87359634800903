import { computed, Injectable, signal } from '@angular/core';
import Session from 'supertokens-web-js/recipe/session';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { environment } from '../../environments/environment';
import { CapacitorCookies, CapacitorHttp } from '@capacitor/core';
import { AlertService } from './alert.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AccountService } from '../pages/account/account.service';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './chat.service';

// import { StorageService } from './storage.servcie';

export interface VerificationData {
  type: 'sms' | 'email';
  email?: string;
  phone?: string;
  preAuthSessionId: string;
  deviceId: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _patientData = signal<any>(undefined);
  private readonly _logo = signal<string>('assets/next-level-logo.svg');
  private readonly _language = signal<'en' | 'es'>('en');
  private readonly _isPrime = signal<boolean>(false);
  private readonly _verificationData = signal<VerificationData | undefined>(undefined);
  private readonly _isSwitchingAccount = signal<boolean>(false);
  logo = computed(this._logo);
  language = computed(this._language);
  isPrime = computed(this._isPrime);
  verificationData = computed(this._verificationData);
  isSwitchingAccount = computed(this._isSwitchingAccount);

  constructor(
    private readonly navCtrl: NavController,
    private readonly alertService: AlertService,
    private readonly httpClient: HttpClient,
    private readonly accountService: AccountService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private readonly modalController: ModalController,
    private readonly chatService: ChatService,
    // private readonly storageService: StorageService,
  ) {}

  updateVerificationData(newData: VerificationData): void {
    this._verificationData.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  setPatientData(patient: any): void {
    this._patientData.set({ ...patient, name: `${patient.firstName} ${patient.lastName}` });
  }

  setSwitchingState(isSwitching: boolean): void {
    this._isSwitchingAccount.update(() => isSwitching);
  }

  get patientData(): any {
    return this._patientData();
  }

  get isPrimeUser(): any {
    return this._isPrime();
  }

  updateLogo(newData: any): void {
    this._logo.update(() => newData);
  }

  updateLanguage(newData: any): void {
    this._language.update(() => newData);
  }

  updatePrime(newData: boolean): void {
    this._isPrime.update(() => newData);
  }

  goToLogin(): void {
    this.navCtrl.navigateRoot(['login']);
  }

  async signOut(): Promise<void> {
    await Session.signOut();
    await CapacitorCookies.clearAllCookies();
    this.clearServices();
    this.goToLogin();
    await this.modalController.dismiss();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const sessionExists = await Session.doesSessionExist();

      if (sessionExists) {
        const errors = (await Session.validateClaims()) as Session.ClaimValidationError[];
        return errors.length === 0;
      }

      return false;
    } catch (error) {
      this.alertService.showError();
      return false;
    }
  }

  async getTokenPayload(): Promise<any> {
    return Session.getAccessTokenPayloadSecurely();
  }

  async getToken(): Promise<string | undefined> {
    return Session.getAccessToken();
  }

  async validatePhoneNumber(phoneNumber: string): Promise<any> {
    try {
      const token = await this.getToken();
      const response = await CapacitorHttp.post({
        url: `${environment.ollaApiUrl}/api/communication/sms/validate/${phoneNumber}`,
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
      });

      if (response.status === 401) {
        await this.signOut();
        return;
      }
      if (response.status !== 201) throw new Error();

      return { phoneNumber: response.data.phoneNumber };
    } catch (error) {
      this.alertService.showError();
      return error;
    }
  }

  async getPatientData(): Promise<any> {
    try {
      const token = await this.getToken();
      const userData = await CapacitorHttp.post({
        url: `${environment.ollaApiUrl}/api/supertokens/patient/me`,
        headers: {
          'content-type': 'application/json',
          'X-TENANT-ID': environment.orgSlug,
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      });

      if (userData.status === 401) {
        await this.signOut();
        return;
      }
      if (!(userData.status >= 200 && userData.status < 300)) throw new Error();

      this.setPatientData(userData.data);
      if (userData.data.language) {
        this.translateService.use(userData.data.language.split('-')[0]);
        this.updateLanguage(userData.data.language.split('-')[0]);
      }

      await this.processPrime();
      this.chatService.setup(userData.data, this._isPrime());
      return userData.data;
    } catch {
      this.alertService.showError();
    }
  }

  async requestVerificationCode(data: any, navigateRoute?: string): Promise<any> {
    try {
      const token = await this.getToken();
      const res = await CapacitorHttp.patch({
        url: `${environment.ollaApiUrl}/api/supertokens/patient/credentials`,
        data,
        headers: {
          'content-type': 'application/json',
          'X-TENANT-ID': environment.orgSlug,
          Authorization: `Bearer ${token!}`,
        },
        responseType: 'json',
      });

      if (res.status === 401) {
        await this.signOut();
        return;
      }
      if (res?.status === 200) {
        this.updateVerificationData({
          type: data.phone ? 'sms' : 'email',
          ...data,
          preAuthSessionId: res.data.preAuthSessionId,
          deviceId: res.data.deviceId,
        });
        await this.navCtrl.navigateForward([navigateRoute]);
      }
      return res;
    } catch {
      this.alertService.showError();
    }
  }

  async verifyCode(data: any, token: string): Promise<any> {
    return firstValueFrom(
      this.httpClient.post(`${environment.ollaApiUrl}/api/supertokens/signinup/code/consume`, data, {
        headers: {
          'content-type': 'application/json',
          'X-TENANT-ID': environment.orgSlug,
          'X-REDIRECT-URI': environment.redirectUri,
          'X-ORIGIN': environment.redirectUri,
          Authorization: `Bearer ${token}`,
        },
      }),
    );
  }

  validatePatient(data: any, token: string): Promise<any> {
    return CapacitorHttp.post({
      url: `${environment.ollaApiUrl}/api/supertokens/patient/validate`,
      data,
      headers: {
        'content-type': 'application/json',
        'X-TENANT-ID': environment.orgSlug,
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async replaceToken(account: any): Promise<void> {
    const tokenPayload = await this.getTokenPayload();
    if (account.isAccountHolder && !tokenPayload.subUser) return;
    if (account.isChild && tokenPayload.subUser?.patientId === account.id) return;

    const token = await this.getToken();
    const res = (await firstValueFrom(
      this.httpClient.post(
        `${environment.ollaApiUrl}/api/supertokens/account/switch-account`,
        { patientId: account.id },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-TENANT-ID': environment.orgSlug,
          },
        },
      ),
    )) as HttpResponse<any>;
    if (res.status < 200 || res.status >= 300) throw new Error();
    this.setPatientData(res);
  }

  setPrimeAndLanguage(isPrime: boolean, language: string): void {
    this.updatePrime(isPrime);
    this.updateLogo(this.isPrime() ? 'assets/prime-logo.svg' : 'assets/next-level-logo.svg');
    this.updateLanguage(language.split('-')[0]);
    this.translateService.use(language.split('-')[0]);
  }

  private clear(): void {
    this._patientData.set(undefined);
    this._verificationData.set(undefined);
  }

  private clearServices(): void {
    this.accountService.clear();
    this.languageService.clear();
    // this.storageService.clear();
    this.clear();
  }

  private async processPrimeFromToken(): Promise<void> {
    const token = await this.getTokenPayload();
    this.isPrime = token.isPrime;
    this.updateLogo(token.isPrime ? 'assets/prime-logo.svg' : 'assets/next-level-logo.svg');
  }

  private async processPrime(): Promise<void> {
    try {
      const token = await this.getToken();

      const primeCheck = await CapacitorHttp.get({
        headers: {
          'content-type': 'application/json',
          'X-TENANT-ID': environment.orgSlug,
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        url: `${environment.ollaApiUrl}/api/patient/v1/patient/${this.patientData.id}/info`,
      });
      if (primeCheck.status === 401) {
        await this.signOut();
        return;
      }

      if (primeCheck.status !== 200) throw new Error();

      this.setPrimeAndLanguage(primeCheck.data.metadata.nl.isPrime, primeCheck.data.language);
    } catch {
      await this.processPrimeFromToken();
    }
  }
}
